
























import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";

import EntityCard from "@/accounting-portal/components/counterparties/EntityCard.vue";
import ThresholdsTable from "@/accounting-portal/components/counterparties/thresholds/ThresholdsTable.vue";
import ThresholdsForm from "@/accounting-portal/components/counterparties/thresholds/ThresholdsForm.vue";
import ThresholdRequestModel from "@/accounting-portal/models/counterparties/ThresholdRequestModel";
import ThresholdResponseModel from "@/accounting-portal/models/counterparties/ThresholdResponseModel";
import { AppSection } from "@/shared/models";
import AppSectionAccessMixin from "@/shared/mixins/AppSectionAccessMixin";

@Component({
  components: {
    EntityCard,
    ThresholdsTable,
    ThresholdsForm,
  },
})
export default class ThresholdsView extends mixins(AppSectionAccessMixin) {
  visible = false;
  localValue = new ThresholdRequestModel();
  editingItem: ThresholdResponseModel | null = null;

  get saving(): boolean {
    return this.$store.state.counterpartyStore.isThresholdSaving;
  }

  get thresholds(): Array<ThresholdResponseModel> {
    return this.$store.state.counterpartyStore.thresholds;
  }

  get formTitle(): string {
    return this.editingItem
      ? this.$lang("accountingPortal.counterparties.thresholds.editTitle")
      : this.$lang("accountingPortal.counterparties.thresholds.newTitle");
  }

  get counterpartyId(): number {
    return Number(this.$route.params.counterpartyId);
  }

  get appSection(): AppSection {
    return AppSection.COUNTERPARTIES;
  }

  created() {
    this.$store.dispatch("loadThresholds", this.counterpartyId);
  }

  beforeDestroy() {
    this.$store.commit("clearThresholds");
  }

  handleChangeVisible(value: boolean) {
    this.visible = value;

    if (!value) {
      this.localValue = new ThresholdRequestModel();
      this.editingItem = null;
    }
  }

  handleClickByTableRow(item: ThresholdResponseModel) {
    if (!this.hasEditAccess) {
      return;
    }

    this.editingItem = ThresholdResponseModel.of(item);
    this.localValue = new ThresholdRequestModel(
      this.counterpartyId,
      item.type,
      item.absoluteValue
    );
    this.visible = true;
  }

  async handleSave() {
    if (this.editingItem) {
      await this.$store.dispatch("updateThreshold", {
        id: this.editingItem.id,
        payload: this.localValue,
      });
    } else {
      this.localValue.counterpartyId = this.counterpartyId;
      await this.$store.dispatch("createThreshold", this.localValue);
    }

    this.handleChangeVisible(false);
  }
}
