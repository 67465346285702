





























import { Component, Prop, Vue } from "vue-property-decorator";

import ThresholdRequestModel from "@/accounting-portal/models/counterparties/ThresholdRequestModel";
import ThresholdResponseModel from "@/accounting-portal/models/counterparties/ThresholdResponseModel";
import { ThresholdType } from "@/accounting-portal/models/counterparties/ThresholdType";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component
export default class ThresholdsForm extends Vue {
  @Prop() value!: ThresholdRequestModel;
  @Prop() editingItem!: ThresholdResponseModel;

  readonly REQUIRED_RULE = [
    ValidUtil.required(this.$lang("validation.required")),
  ];

  get localValue(): ThresholdRequestModel {
    return this.value;
  }

  set localValue(value: ThresholdRequestModel) {
    this.$emit("input", value);
  }

  get thresholds(): Array<ThresholdResponseModel> {
    return this.$store.state.counterpartyStore.thresholds;
  }

  get thresholdTypes(): Array<{ text: string; value: string }> {
    return Object.values(ThresholdType).flatMap((value: string) => {
      if (
        this.thresholds.every(
          ({ type }) => type === this.editingItem?.type || type !== value
        )
      ) {
        return [
          {
            text: value,
            value,
          },
        ];
      }
      return [];
    });
  }
}
